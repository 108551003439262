<template>
	<div class="card-container" ref="container" data-cursor-target data-cursor-size="lg">
		<a :href="url">
			<div class="card">
				<div class="flex">
					<div class="card-image" v-if="icon"><img :src="getIconUrl(icon)" alt="" /></div>
					<div class="card-index">{{ formattedIndex }}</div>
				</div>
				<h4 class="card-title">{{ title }}</h4>
				<p class="card-text" v-html="text"></p>
			</div>
		</a>
	</div>
</template>

<script>
export default {
	name: 'Card',
	props: {
		url: String,
		index: Number,
		icon: String || null,
		title: String,
		text: String,
	},
	methods: {
		getIconUrl(name) {
			var img = require.context('@/assets/images/', false, /\.svg$/);
			return img('./' + name + '.svg');
		},
	},
	computed: {
		formattedIndex() {
			return this.index < 10 ? `0${this.index}` : this.index.toString();
		},
	},
};
</script>

<style lang="scss" scoped>
.card-container {
	a {
		height: 100%;
		display: block;
		cursor: pointer;

		.card {
			height: 100%;
			background-color: $white;
			box-shadow: 0 30px 40px rgb(226 230 233 / 25%);
			text-align: left;
			padding: 3rem 2rem;
			transition: 750ms cubic-bezier(0.3, 1, 0.7, 1);

			&-image {
				width: 4rem;
				height: 4rem;
				margin-top: 1rem;
			}

			&-index {
				font-size: 4rem;
				font-weight: 700;
				margin-bottom: 2rem;
				color: rgba($text-dark, 0.05);
			}

			&-title {
				font-size: 1.5rem;
				color: $secondary-color;
				font-weight: 600;
				text-transform: capitalize;
				margin-bottom: 1.5rem;
				padding-bottom: 1.5rem;
				position: relative;

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					background-color: $primary-color;
					height: 3px;
					width: 30%;
					transition: 750ms cubic-bezier(0.3, 1, 0.7, 1);
				}
			}

			&-text {
				color: lighten($secondary-color, 20%);
				line-height: 1.5;
				margin-bottom: 2rem;
			}

			&-index,
			&-image,
			&-title,
			&-text {
				transition: 750ms cubic-bezier(0.3, 1, 0.7, 1);
			}

			&:hover {
				background-color: $primary-color;

				.card-index,
				.card-title,
				.card-text {
					color: $white;
				}

				// .card-image {
				// 	filter: brightness(0);
				// }

				.card-title {
					&::after {
						background-color: $white;
					}
				}
			}
			.flex {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	&.dark {
		a {
			.card {
				background-color: $secondary-color;

				&-icon,
				&-link {
					filter: brightness(20);
				}

				&-icon,
				&-title,
				&-text,
				&-link {
					color: $white;
				}

				&:hover {
					background-color: $primary-color;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.card-container {
		a {
			.card {
				pointer-events: none;
				padding: 4rem 2rem;

				&-icon {
					width: 4rem;
					height: 4rem;
				}

				&-title {
					font-size: 1.25rem;
				}

				&-text {
					font-size: 0.875rem;
				}
			}
		}
	}
}
</style>
